.body--home {
  padding-left: $spacing-unit / 2;
  padding-right: $spacing-unit / 2;

  @include mobileAndAbove {
    padding-left: $spacing-unit;
    padding-right: $spacing-unit;
  }
}

.wrapper--home {
  width: 100%;
  max-width: $tilt2-page-width;
  font-family: $tilt2-font-sans;
  font-size: $tilt2-font-size-body;
  font-weight: $tilt2-font-weight-sans-light;
  line-height: $tilt2-line-height-body;
}

.Home-sectionHeading {
  @include font-heading;
}

.Home-subsectionHeading {
  @include font-heading-small;
}
.Home-subsectionHeading--resources {
  display: flex;
  align-items: center;
  margin-bottom: $spacing-unit / 2;
}


// Hero --------------------------------------------------
.Home-hero {
  display: flex;
  align-items: center;
  margin-bottom: $spacing-unit;

  @include mobileAndBelow {
    flex-direction: column;
  }
  @include mobileAndAbove {
    margin-bottom: $spacing-unit * 1.5;
  }
}

.Home-heroText {
  flex: 1;
}

.Home-heroText-title {
  @include font-hero;
  
  @include mobileAndAbove {
    max-width: 90%;
    margin-bottom: $spacing-unit * 2;
  }
}

.Home-heroText-subtitle {
  @include font-hero-subhead;
  
  @include mobileAndAbove {
    max-width: 90%;
  }
}

.Home-heroText-subtitle-code {
  font-family: $tilt2-font-mono;
  font-size: inherit;
  background-color: $tilt2-color-gray-lightest;
  border: 1px solid $tilt2-color-gray-lighter;
  border-radius: $tilt2-border-radius;
  padding-left: $spacing-unit / 4;
  padding-right: $spacing-unit / 4;
  line-height: 1;
  white-space: nowrap;
}

.Home-heroIllustration {
  width: 80%;
  max-width: 130px;
}


// CTA --------------------------------------------------
.Home-cta {
  margin-bottom: $spacing-unit * 2.5;

  @include mobileAndAbove {
    display: flex;
    justify-content: center;
  }
}

.Home-cta-option {
  display: flex;
  flex-direction: column;
  align-items: center;

  & + & {
    @include mobileAndAbove {
      margin-left: $spacing-unit;
    }
  }
}

.Home-cta-button {
  background-color: transparent;
  font-family: $tilt2-font-sans;
  font-size: $tilt2-button-font-size--large;
  font-weight: $tilt2-font-weight-sans-bold;
  height: $tilt2-button-height--large;
  min-width: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $tilt2-border-radius;
  border: 2px solid $tilt2-color-gray-lighter;
  color: $tilt2-color-gray;
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    color: $color-white;
    background-color: $tilt2-color-gray;
  }
}

.Home-cta-button--primary {
  color: $color-green;
  border-color: $color-green;

  &:hover {
    color: $color-white;
    background-color: $color-green;
  }
}

.Home-cta-option-text {
  @include font-body-small;
  margin-top: $spacing-unit / 4;

  @include mobileAndBelow {
    margin-bottom: $spacing-unit;
  }
}

.modal-background {
  position: absolute;
  z-index: $z-modal-background;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .65);
  display: none;
  
  &.is-blacked-out {
    position: fixed;
    display: block;
  }
}

.popup-trigger {
  display: inline-block;
}

.modal {
  background-color: $color-white;
  position: fixed;
  box-sizing: border-box;
  padding-top: $spacing-unit;
  padding-bottom: $spacing-unit;
  padding-left: $spacing-unit * 1.5;
  padding-right: $spacing-unit * 1.5;
  display: none;
  opacity: 0;
  pointer-events: none;
  transition: all $anim-duration ease-in-out;
  z-index: $z-modal;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  @include media-query-above(1000px) {
    box-sizing: content-box;
    width: 100%;   
    max-width: 900px;
    top: 50%;
    bottom: auto;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);    
  }

  &.is--visible {
    opacity: 1;
    pointer-events: auto;
    overflow-y: scroll;
  }
}

.modal-title {
  @include font-hero;
}

.modal-close {
  position: absolute;
  top: $spacing-unit;
  right: $spacing-unit;
  @include reset-button-style;
  cursor: pointer;
}

.modal-content {
  @include mobileAndAbove {
    display: flex;
  }
}

.modal-content__text {
  @include mobileAndBelow {
    margin-top: $spacing-unit;
  }
  
  @include mobileAndAbove {
    width: 50%;
    padding-left: $spacing-unit * 1.5;
  }
}

.modal-heroText {
  @include font-hero-subhead;
}

.modal-content--highlight {
  color: $color-skyBlue;  
}

.modal-form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.modal-form-row {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  color: $tilt2-color-gray;
  margin-bottom: $spacing-unit * 0.5;
}

.modal-form-input {
  margin-top: $spacing-unit * 0.25;
  height: $tilt2-input-height--large;
  padding-left: $spacing-unit * 0.25;
  padding-right: $spacing-unit * 0.25;
  @include font-body;
}

.modal-form-button {
  margin-top: $spacing-unit * 0.5;
  width: 100%;
}

// Product --------------------------------------------------
.Home-product {
  margin-bottom: $spacing-unit * 3;

  @include mobileAndAbove {
    position: relative;
  }
}
.Home-product-UI {
  @include shadow;
}
.Home-product-Tiltfile {
  @include shadow;
  background-color: $color-white;
  border-radius: $tilt2-border-radius-large;
  overflow: hidden;
  
  @include mobileAndBelow {
    margin-top: $spacing-unit;
  }
  
  @include mobileAndAbove {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(10%, 80%);
  }
}
.Home-product-Tiltfile-header {
  position: relative;
  font-family: inherit;
  padding-top: $spacing-unit / 8;
  padding-bottom: $spacing-unit / 8;
  background-color: $tilt2-color-gray-lightest;
  text-align: center;
}
.Home-product-Tiltfile-header-chromeDecoration {
  position: absolute;
  left: $spacing-unit / 4;
}
.chrome-button1 {fill: $color-red;}
.chrome-button2 {fill: $color-yellow;}
.chrome-button3 {fill: $color-green;}

.Home-product-Tiltfile-code {
  display: block;
  padding: $spacing-unit;
  @include font-mono;  

  p {
    margin: 0;
    min-height: $spacing-unit / 2; // Preserve blank lines
  }

  p + p {
    margin-top: $spacing-unit / 8;
  }
}

.tiltfile-comment {
  color: $tilt2-color-gray-lighter;
}
.tiltfile-arg {
  color: $color-blue;
}
.tiltfile-arg-value {
  color: $color-pink;
}

.Home-product-caption {
  margin-top: $spacing-unit * 1.5;
  text-align: center;
}

// Features Intro --------------------------------------------------
.Home-featuresIntro-text {
  font-size: $tilt2-font-size-hero-smallest;
  line-height: $tilt2-line-height-hero;
  font-weight: $tilt2-font-weight-sans-light;
  margin-bottom: $spacing-unit;
  
  @include mobileAndAbove {
    font-size: $tilt2-font-size-hero-small;
    margin-bottom: $spacing-unit * 2;
  }
}

.Home-featuresIntro-text-button {
  @include reset-button-style;
  font-weight: inherit;
}


.Home-featuresIntro-text-button--pillar1 {
  color: $color-pink;
}
.Home-featuresIntro-text-button--pillar2 {
  color: $color-blue;
}
.Home-featuresIntro-text-button--pillar3 {
  color: $color-purple;
}

// Features --------------------------------------------------

// The interaction we want here is that the navList
// determines the size of the content box, so we make the contentList
// an absolutely positioned element inside Home-features.

.Home-features {
  margin-bottom: $spacing-unit;
  position: relative;
  
  @include mobileAndAbove {
    display: flex;
    margin-bottom: $spacing-unit * 3;
  } 
}

$Home-feature-navList-width: 35%;

.Home-features-navList {
  @include reset-list-style;

  @include mobileAndAbove {
    width: $Home-feature-navList-width;
    padding-right: $spacing-unit * 1.5;
    box-sizing: border-box;
  }
}

.Home-features-navItem + .Home-features-navItem {
  margin-top: $spacing-unit * .25;
}

.Home-features-navItem-description {
  transition: height $anim-duration ease;
  z-index: 1;
  position: relative;
  height: 0;
  overflow: hidden;

  @include mobileAndAbove {
    display: none;
  }
  
  .Home-features-navItem.is-active & {
    height: 100%;
    border: 1px solid $tilt2-color-gray-lighter;
    border-width: 0 1px 1px 1px;
    border-radius: 0 0 4px 4px;
  }
  
  .Home-features-navItem--pillar1.is-active & {
    border-color: $color-pink;
  }
  .Home-features-navItem--pillar2.is-active & {
    border-color: $color-blue;
  }
  .Home-features-navItem--pillar3.is-active & {
    border-color: $color-purple;
  }
}
.Home-features-navItem-descriptionInner {
  padding: $spacing-unit / 2;
  transition: transform $anim-duration ease;
  transform: translate(0, -100%);
  
  .Home-features-navItem.is-active & {
    transform: translate(0, 0);
  }
}

.Home-features-navItem-button {
  @include reset-button-style;
  background-color: $color-white;
  cursor: pointer;
  height: 40px;
  padding-left: $spacing-unit / 2;
  padding-right: $spacing-unit / 2;
  border: 1px solid $tilt2-color-gray-lighter;
  border-radius: $tilt2-border-radius-large;
  width: 100%;
  transition: color $anim-duration-short linear,
              background-color $anim-duration ease,
              border-color $anim-duration ease;

  .Home-features-navItem.is-active & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.Home-features-navItem-button {
  .Home-features-navItem--pillar1 & {
    color: $color-pink;
  }

  .Home-features-navItem--pillar1:hover &,
  .Home-features-navItem--pillar1.is-active & {
    color: $color-white;
    background-color: $color-pink;
    border-color: $color-pink;
  }
}

.Home-features-navItem-button {
  .Home-features-navItem--pillar2 & {
    color: $color-blue;
  }
  
  .Home-features-navItem--pillar2:hover &,
  .Home-features-navItem--pillar2.is-active & {
    color: $color-white;
    background-color: $color-blue;
    border-color: $color-blue;
  }
}

.Home-features-navItem-button {
  .Home-features-navItem--pillar3 & {
    color: $color-purple;
  }
  
  .Home-features-navItem--pillar3:hover &,
  .Home-features-navItem--pillar3.is-active & {
    color: $color-white;
    background-color: $color-purple;
    border-color: $color-purple;
  }
}

.Home-features-contentList {
  @include reset-list-style;
  padding-top: $spacing-unit * 0.75;
  padding-right: $spacing-unit; // Text shouldn't get too close to scrollbar
  padding-bottom: $spacing-unit * 2.25;
  position: absolute;
  top: 0;
  bottom: 0;
  left: $Home-feature-navList-width;
  right: 0;
  overflow-y: scroll;

  @include mobileAndBelow {
    display: none;
  }
}

.Home-features-contentItem + .Home-features-contentItem {
  margin-top: $spacing-unit * 1.25;
}

.Home-features-contentItem-title {
  @include reset-button-style;
  @include font-heading-small;
}

// There's a white gradient at the top and bottom of the feature list.
// First, visually align the nav with the content...
.Home-sectionHeading--features {
  @include mobileAndAbove {
    margin-bottom: 0;
  }
}
.Home-features-navList {
  @include mobileAndAbove {
    padding-top: $spacing-unit;
    padding-bottom: $spacing-unit;
  }
}
// ...Then add the actual gradient:
.Home-features-contentList-gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: $Home-feature-navList-width;
  right: 0;
  pointer-events: none;
  z-index: 1; // Make sure it's above the text

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 95%;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  }

  &::after {
    content: "";
    position: absolute;
    top: 80%;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  }

  @include mobileAndBelow {
    display: none;
  }

}


// Video --------------------------------------------------

// https://css-tricks.com/fluid-width-video/

.Home-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin-bottom: $spacing-unit * 3;
  @include shadow;
}

.Home-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Testimonials --------------------------------------------------
.Home-sectionHeading--testimonials {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Home-testimonials-navList {
  display: flex;
  align-items: center;
}
.Home-testimonials-navItem-svg .fillStd {
  fill: $tilt2-color-gray-lighter;
  transition: fill $anim-duration ease;
}
.Home-testimonials-navItem {
  @include reset-button-style;
  cursor: pointer;
  margin-left: $spacing-unit;
  outline: none !important;

  &:hover .fillStd,
  &.is-selected .fillStd {
    fill: $color-blue;
  }
}
.Home-testimonials-navItem-svg--clusterApi {
  width: 40px;
}

.Home-testimonials-navItem-svg--mux {
  width: 40px;
}

.Home-testimonialBlock {
  overflow: hidden;
}
.Home-testimonialList {
  @include reset-list-style;
  display: flex;
  flex-wrap: nowrap;
  transition: transform $anim-duration ease;
}

.Home-testimonial {
  margin-bottom: $spacing-unit;
  background-color: $color-off-white;
  border-radius: $tilt2-border-radius;
  border: 1px solid $tilt2-color-gray-lightest;
  color: $tilt2-color-gray;
  padding: $spacing-unit / 2;
  margin-right: $spacing-unit * 2;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;

  @include mobileAndAbove {
    display: grid;
    grid-template-columns: 1fr 7fr;
    column-gap: $spacing-unit * 2;
    margin-bottom: $spacing-unit * 2;
    padding: $spacing-unit * 1.5;
  } 
}

.Home-testimonial-profile {
  font-size: $tilt2-font-size-body-small;

  @include mobileAndBelow {
    display: flex;
    margin-bottom: $spacing-unit / 2;
  }
  @include mobileAndAbove {
    flex: 1;
  }
}

.Home-testimonial-profile-photo {
  @include mobileAndBelow {
    flex: 1;
    padding-right: $spacing-unit / 2;
  }
  @include mobileAndAbove {
    margin-bottom: $spacing-unit / 4;
  }
}
.Home-testimonial-profileInfo {
  @include mobileAndBelow {
    flex: 3;
  }
}


.Home-testimonial-profileInfo-name {
  font-weight: $tilt2-font-weight-sans-bold;
  margin-bottom: 0;
}
.Home-testimonial-profileInfo-role {
  margin-bottom: $spacing-unit / 4;
}
.Home-testimonial-profileSocial-link {
  & + & {
    margin-left: $spacing-unit / 2;
  }
  &:hover {
    background-color: transparent;
  }
}

.Home-testimonial-profileSocial-link--twitter {
  .primaryStroke {
    stroke: $color-blue
  }
  .fillStd {
    fill: transparent;
  }
}
.Home-testimonial-profileSocial-link--web {
  .fillStd {
    fill: $color-blue;
  }
}

.Home-testimonial-content {  
  @include mobileAndAbove {
    flex: 6;
  }
}
 .Home-testimonial-content-quote {
   font-family: $tilt2-font-serif;
   line-height: $tilt2-line-height-body-serif;
   margin-bottom: $spacing-unit;
   
   @include mobileAndAbove {
    font-size: $tilt2-font-size-body-serif;
   }
 }
 .Home-testimonial-content-meta {
   font-size: $tilt2-font-size-body-small;
   line-height: $tilt2-line-height-body-serif;
   display: flex;
   align-items: flex-start;
   
   @include mobileAndAbove {
    align-items: center;
   }
}
.Home-testimonial-content-meta-svg {
  margin-right: $spacing-unit / 2;
  max-width: 50px;
  fill: $tilt2-color-gray-lighter;
}
.Home-testimonial-content-meta-text {
  margin: 0;
 }

// Resources --------------------------------------------------

.Home-resources {
  margin-bottom: $spacing-unit * 2;
}

.Home-resources-list {
  @include reset-list-style;
  
  @include mobileAndAbove {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: $spacing-unit;
    row-gap: $spacing-unit;
  }
}

.Home-resources-listItem {
  @include shadow;
  border: 1px solid $tilt2-color-gray-lightest;
  padding: $spacing-unit / 2;
  border-radius: $tilt2-border-radius;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mobileAndBelow {
    & + & {
      margin-top: $spacing-unit;
    }
  }
}
.Home-resources-listItem-text {
  margin-bottom: $spacing-unit / 4;
}

.Home-resources-svg {
  margin-right: $spacing-unit / 2;
  fill: $color-blue;
}
.Home-resources-button {
  @include reset-button-style;
  line-height: $tilt2-line-height-body;
  width: 100%;
  cursor: pointer;
}
.Home-resources-link,
.Home-resources-button {
  display: block;
  font-family: $tilt2-font-sans;
  font-weight: $tilt2-font-weight-sans-bold;
  font-size: $tilt2-font-size-body;
  line-height: $tilt2-line-height-body;
  padding-top: $spacing-unit / 4;
  padding-bottom: $spacing-unit / 4;
  border-radius: $tilt2-border-radius;
  border: 1px solid $color-blue;
  text-align: center;
  color: $color-blue;
  transition: color $anim-duration ease, 
    background-color $anim-duration ease;

  &:hover {
    color: $color-white;
    background-color: $color-blue;
  }
  &:active {
    background-color: $color-blue-light;
  }

  & + & {
    margin-top: $spacing-unit / 4;
  }
}
.Home-resources-link-meta {
  font-weight: $tilt2-font-weight-sans-light;
}
.Home-resources-label {
  font-size: $tilt2-font-size-body-small;
  font-weight: $tilt2-font-weight-sans-bold;
  margin-bottom: $spacing-unit / 8;
  display: block;
}
.Home-resources-input {
  font-family: $tilt2-font-sans;
  font-size: $tilt2-font-size-body;
  padding-left: $spacing-unit / 2;
  padding-right: $spacing-unit / 2;
  padding-top: $spacing-unit / 4;
  padding-bottom: $spacing-unit / 4;
  width: 100%;
  margin-bottom: $spacing-unit / 4;

  &::placeholder {
    color: $tilt2-color-gray;
  }
}
